@tailwind components;
@tailwind utilities;
@tailwind base;
@tailwind variants;

@layer components {
    .link {
        @apply text-gray-800 transition-colors hover:text-red-500 cursor-pointer;

        &.active {
            @apply text-red-500;
        }
    }

    button.button-primary,
    a.button-primary,
    label.button-primary {
        @apply bg-orange-600 rounded-lg cursor-pointer text-white text-sm px-4 transition-colors hover:bg-orange-700 py-2.5 inline-block;
    }

    button.button-primary-outlined,
    a.button-primary-outlined,
    label.button-primary-outlined {
        @apply border border-orange-600 cursor-pointer rounded-lg text-red-600 text-sm px-4 transition-colors hover:border-orange-700 hover:bg-orange-700 hover:text-white py-2.5 inline-block;
    }

    button.button-primary-outlined.dark,
    a.button-primary-outlined.dark,
    label.button-primary-outlined.dark {
        @apply border border-orange-600 cursor-pointer rounded-lg text-white text-sm px-4 transition-colors hover:border-orange-700 hover:bg-orange-700 py-2.5 inline-block;
    }

    button.button-cancel {
        @apply border border-gray-500 rounded-lg text-black text-sm px-4 py-2.5 inline-block hover:border-gray-600;
    }

    .input {
        @apply w-full border p-2 rounded-md transition-colors hover:border-orange-600;
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
    font-size: 0.9rem;
}

[disabled] {
    opacity: 0.75;
    pointer-events: none;
    cursor: default;
}
